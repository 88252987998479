import React from 'react';
import { Routes, Route } from "react-router-dom";

import PractitionersList from './PractitionersList';

const AdminRoutes = () => {
    return (
        <Routes>
            <Route path="/practitioners" element={<PractitionersList />} />
        </Routes>
    );
};

export default AdminRoutes;
