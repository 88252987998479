import React from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { getStageName } from '../../utils/mapping';

type GameDuration = {
  hour: number,
  minute: number,
  second: number
};

type GameChapterState = {
  workshop: number,
  completed: boolean
};

type DateEva = {
  year: number,
  month: number,
  day: number
};

type EVA = {
  date: DateEva,
  anxiety: number,
  physicalSelfEsteem: number,
  morale: number,
  motivation: number,
  selfEsteem: number,
  physicalActivityWanted: number
};

export interface StageInfoCardProps {
    chapitreId: string,
    states: GameChapterState[],
    gameDuration: GameDuration,
    evas: EVA[]
}

export const SaveGameInfoCard = ({
    chapitreId,
    states,
    gameDuration,
    evas
}: StageInfoCardProps) => {
    return (
    <Card sx={{ maxWidth: 500, marginBottom: 5 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Chapitre {`${chapitreId}:`}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
            <ul>
              {
                states?.map(state => 
                  <li>
                    {getStageName(state.workshop)}: {state.completed ? "Terminé" : "A faire"}
                  </li>
                  )
              }
            </ul>
        </Typography>
      </CardContent>
    </Card>
    );
};
