import React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import useAuthedQuery from '../../hooks/useAuthQuery';
import { mainApp } from '../../utils/api';
import { SaveGameInfoCard } from './SaveGameInfoCard';
import { SaveGame } from '../../models/SaveGame';

const Patient = () => {
    const { id } = useParams();

    const { isLoading, error, data: patient, isFetching } = useAuthedQuery(['patients', id], () =>
        mainApp.service("patients").get(id)
    );

    /*const { isLoadingGameSessions, errorGameSession, data: gameSessions, isFetchingGameSession } = useAuthedQuery(['game-sessions', id], () =>
      mainApp.service("game-sessions").find({query : { userId: id }})
    );*/

    const { isLoadingSaveGames, errorSaveGames, data: saveGames, isFetchingSaveGames } = useAuthedQuery(['save-games-l1', id], () =>
      mainApp.service("save-games").find({query : { userId: id, $limit: 1, $sort: { updatedAt: -1 } }})
    );

    const saveGame = saveGames?.data?.[0] as SaveGame;

    return (<>
        <Box padding={2}>
          <Typography component="h1" variant="h5" marginBottom={5}>
            Profil du patient:
          </Typography>
          <TableContainer component={Paper} sx={{ maxWidth: 500 }}>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
              <TableBody>
                <TableRow
                  key='lastName'
                >
                <TableCell component="th" scope="row">
                  Nom:
                </TableCell>
                <TableCell component="th" scope="row">
                    {patient?.lastName}
                </TableCell>
                </TableRow>
                <TableRow
                  key='firstname'
                >
                <TableCell component="th" scope="row">
                  Prénom:
                </TableCell>
                <TableCell component="th" scope="row">
                    {patient?.firstName}
                </TableCell>
                </TableRow>
                <TableRow
                  key='birthdate'
                >
                <TableCell component="th" scope="row">
                  Date de naissance:
                </TableCell>
                <TableCell component="th" scope="row">
                    {new Date(patient?.birthDate * 1000).toLocaleDateString()}
                </TableCell>
                </TableRow>
                <TableRow
                  key='sex'
                >
                <TableCell component="th" scope="row">
                  Sexe:
                </TableCell>
                <TableCell component="th" scope="row">
                    {patient?.sex}
                </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            </TableContainer>
            <Typography component="h1" variant="h5" marginBottom={5}  marginTop={5}>
                Programme de mon patient:
            </Typography>
                {saveGame?.data ? <SaveGameInfoCard
                    chapitreId={saveGame.data.currentChapter}
                    states={saveGame.data.currentChapterState}
                    gameDuration={saveGame.data.date}
                    evas={saveGame.data.EVAs}
                /> : <Typography>
                  Aucune sauvegarde
                </Typography>}
          </Box>
    </>);
};

export default Patient;
