const stageToNameMapping = [
    "Premiere experience utilisateur",
    "Découverte du compagnon",
    "Découverte du Hub",
    "Roue de la motivation",
    "Mur des accomplissement + Priorisation du changement",
    "Bulles salle de bain",
    "Poupées Russes",
    "EVA",
    "Silhouettes",
    "Portes aux formes",
    "Boxe attentive",
    "Boxe sélective",
    "Tower defense inverse",
    "Quiz alimentaire",
    "La pêche aux calories",
    "Illusion des contenants",
    "Yoga",
    "Prévention de la rechute",
    "Consolidation des acquis",
    "Complications médicales des TCA"
];

export const getStageName = (stageId: number): string => {
    return stageToNameMapping[stageId];
}