import React, { useEffect, useState } from 'react';
import { GridToolbar, GridRowId, GridActionsCellItem, DataGrid, GridColumns, GridRowModel, GridRowParams, GridValidRowModel } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import useAuthedQuery from '../../hooks/useAuthQuery';
import { Patient } from '../../models/Patient';
import { mainApp } from '../../utils/api'; 
import { useNavigate } from 'react-router-dom';

const NoPatients = () => {
    return (<Box textAlign='center' width='100%' marginTop={2}>Pas de patients</Box>);
};

const PatientsList = () => {
    const [ patients, setPatients ] = useState<Patient[]>([]);

    const { isLoading, error, data, isFetching, refetch } = useAuthedQuery(['patients'], () =>
        mainApp.service("patients").find()
    );

    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            setPatients(data?.data.map((r: Patient) => ({...r, id: r._id})));
        }
    }, [data]);

    const update = async (newRow: GridRowModel): Promise<GridValidRowModel> => {
        return mainApp.service('patients').patch(newRow._id, newRow);
    };

    const remove = (id: string): Promise<GridValidRowModel> => {
        return mainApp.service('patients').remove(id);
    };

    const handleClick = (params: GridRowParams<Patient>) => {
        navigate(params.id as string);
    };

    const handleDeleteClick = (id: GridRowId) => async () => {
        if (confirm(`Vous allez supprimer le patient ${id}`)) {
            await remove(id.toString());
            refetch();
        }
    };

    const columns: GridColumns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'firstName',
          headerName: 'Prénom',
          width: 150,
          editable: true,
        },
        {
            field: 'lastName',
            headerName: 'Nom',
            width: 150,
            editable: true,
        },
        {
            field: 'sex',
            headerName: 'Sexe',
            width: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 150,
            editable: false,
        },
        {
            field: 'birthDate',
            headerName: 'Date de naissance',
            width: 150,
            editable: false,
            valueFormatter: ({ value }) => {
                return new Date(value * 1000).toLocaleDateString()
            }
        },
        {
            field: 'createdAt',
            headerName: 'Date de création',
            width: 150,
            editable: false,
            valueFormatter: ({ value }) => {
                return new Date(value).toLocaleDateString()
            }
        },
        {
            field: 'updatedAt',
            headerName: 'Date de modification',
            width: 150,
            editable: false,
            valueFormatter: ({ value }) => {
                return new Date(value).toLocaleDateString()
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: (row: any) => {
                return [
                  <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleDeleteClick(row.id)}
                    color="inherit"
                  />
                ]
            }
        }
    ];

    return (
        <Box sx={{ height: '90vh', width: '100%' }}>
            <DataGrid
                rows={patients}
                columns={columns}
                loading={isLoading}
                pageSize={5}
                rowsPerPageOptions={[5]}
                onRowClick={handleClick}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                processRowUpdate={update}
                components={{
                    NoRowsOverlay: NoPatients,
                    Toolbar: GridToolbar
                }}
            />
            </Box>
    );
};

export default PatientsList;
