import React, { useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Grow from '@mui/material/Grow';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { Patient } from '../../models/Patient';

import { mainApp } from '../../utils/api';

interface ProfileProps {
    me: Patient
}

const PatientProfile = (props: ProfileProps) => {
  const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors, isSubmitting, isSubmitSuccessful },
        watch
  } = useForm();

  const watchFields = watch(['seenVideo', 'information', 'dataUsage', 'sex'], {
    seenVideo: false,
    information: false,
    dataUsage: false
  });

  const theme = useTheme();

  useEffect(() => {
    if(props.me) {
        Object.keys(props.me).forEach((m: string) => {
             if(m === 'password') {
              return;
             }
             if(m === 'birthDate') {
              // @ts-ignore
              const date = new Date(props.me[m] * 1000);
              setValue(m, date.toISOString().split('T')[0]);
              return;
             }
             // @ts-ignore
             setValue(m, props.me[m]);
        });
    }
  }, [props.me]);

  const updateAccount = async (data: any) => {
    try {
      data.birthDate = new Date(data.birthDate).getTime() / 1000;
      await mainApp.service("patients").patch(props.me._id, data);
    } catch(err: any) {
      if(!err.errors || !Object.keys(err.errors).length) {
        setError('email', {
          message: err?.message
        });
      } else {
        Object.keys(err.errors).forEach(k => {
          setError(k, {
            message: err.errors[k]
          });
        });
      }
    }
  };

  return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Profil
          </Typography>
          <Typography component="h1" variant="h5">
            Edition
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit(updateAccount)} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Nom"
                  autoComplete="family-name"
                  error={!!errors.lastName?.message}
                  helperText={errors.lastName?.message as string}
                  {...register('lastName', { required: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  required
                  fullWidth
                  label="Prénom"
                  error={!!errors.firstName?.message}
                  helperText={errors.firstName?.message as string}
                  {...register('firstName', { required: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  type="date"
                  label="Date de naissance"
                  error={!!errors.birthDate?.message}
                  helperText={errors.birthDate?.message as string}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('birthDate', { required: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="sex-select-label">Sexe</InputLabel>
                  <Select
                    labelId="sex-select-label"
                    id="sex-simple-select"
                    label="Sexe"
                    placeholder='Sexe'
                    defaultValue='feminin'
                    required
                    onChange={(e: SelectChangeEvent) => setValue('sex', e?.target?.value as string)}
                    value={getValues('sex') || ''}
                  >
                    <MenuItem value='feminin'>Féminin</MenuItem>
                    <MenuItem value='masculin'>Masculin</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  disabled
                  fullWidth
                  label="Email"
                  autoComplete="email"
                  error={!!errors.email?.message}
                  helperText={errors.email?.message as string}
                  {...register('email', {
                    required: true,
                    minLength: {
                      value: 4,
                      message: 'L\'email doit contenir au moins 4 caractères'
                    },
                    maxLength: {
                      value: 50,
                      message: 'L\'email ne doit pas dépasser 50 caractères'
                    }
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <ul>
                    <li>
                        <FormControlLabel
                          control={<Checkbox color="primary" />}
                          label="j’ai vu la vidéo (mettre lien vidéo)"
                          onChange={(e, checked: boolean) => setValue('seenVideo', checked)}
                          checked={!!getValues('seenVideo')}
                          disabled
                        />
                      </li>
                      <li style={{
                        marginTop: 10,
                        marginBottom: 15
                      }}>
                        <FormControlLabel
                          control={<Checkbox color="primary" />}
                          label="Mon professionnel de santé m’a informé sur l’utilisation de la solution et l’utilisation de mes données personnelles dans le cadre de l’application (mettre lien note d’info)"
                          onChange={(e, checked: boolean) => setValue('information', checked)}
                          checked={!!getValues('information')}
                          disabled
                        />
                      </li>
                      <li>
                        <FormControlLabel
                          control={<Checkbox color="primary" />}
                          label="J’accepte l’utilisation de la solution et l’utilisation de mes données personnelles pour l’utilisation de la solution"
                          onChange={(e, checked: boolean) => setValue('dataUsage', checked)}
                          checked={!!getValues('dataUsage')}
                        />
                      </li>
                    </ul>
                    {(errors.seenVideo || errors.dataUsage || errors.information) && <Typography align='center' sx={{
                      color: theme.palette.error.light
                    }}>
                        Toute les cases doivent être coché pour valider le compte
                      </Typography>}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Link href="/forgot-password" variant="body2">
                  Changer le mot de passe
                </Link>
              </Grid>
            </Grid>
            {
            isSubmitSuccessful ? 
              <Grow in={isSubmitSuccessful}><Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                <Typography variant="h6">Votre compte a été mis à jour avec succès</Typography>
              </Box></Grow>
            :
            isSubmitting ?
            <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></Box>
            : <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Valider
            </Button>}
          </Box>
        </Box>
      </Container>);
};

export default PatientProfile;
