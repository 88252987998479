import * as React from 'react';
import { useForm } from 'react-hook-form';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Grow from '@mui/material/Grow';
import { useTheme } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import logo from '../../images/logo.jpeg';

import { mainApp } from '../../utils/api';

export default function SignUpPro() {
  const {
        register,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting, isSubmitSuccessful },
        watch
  } = useForm();
  const theme = useTheme();

  const createAccount = async (data: any) => {
    try {
      await mainApp.service("practitioners").create(data);
    } catch(err: any) {
      if(!err.errors || !Object.keys(err.errors).length) {
        setError('email', {
          message: err?.message
        });
      } else {
        Object.keys(err.errors).forEach(k => {
          setError(k, {
            message: err.errors[k]
          });
        });
      }
    }
  };

  return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logo} width={100} />
          <Typography component="h1" variant="h5">
            Inscription
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit(createAccount)} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Nom"
                  autoComplete="family-name"
                  error={!!errors.lastName?.message}
                  helperText={errors.lastName?.message as string}
                  {...register('lastName', { required: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  required
                  fullWidth
                  label="Prénom"
                  error={!!errors.firstName?.message}
                  helperText={errors.firstName?.message as string}
                  {...register('firstName', { required: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="job-select-label">Profession *</InputLabel>
                  <Select
                    labelId="job-select-label"
                    id="job-simple-select"
                    label="Profession"
                    placeholder='Profession'
                    {...register('job', { required: true })}
                  >
                    <MenuItem value='médecin'>Médecin</MenuItem>
                    <MenuItem value='pharmacien'>Pharmacien(ne)</MenuItem>
                    <MenuItem value='infirmier'>Infirmier(e)</MenuItem>
                    <MenuItem value='educateur'>Educateur(ice)</MenuItem>
                    <MenuItem value='psychologue'>Psychologue</MenuItem>
                    <MenuItem value='diététicien'>Diététicien(ne)</MenuItem>
                    <MenuItem value='autre'>Autre</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              { false &&
                <>
                <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Adresse de facturation"
                  autoComplete="address"
                  error={!!errors.address?.message}
                  helperText={errors.address?.message as string}
                  {...register('address', { required: true })}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  label="Ville"
                  error={!!errors.city?.message}
                  helperText={errors.city?.message as string}
                  {...register('city', { required: true })}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  label="Code postale"
                  error={!!errors.postalCode?.message}
                  helperText={errors.postalCode?.message as string}
                  {...register('postalCode', { required: true })}
                />
              </Grid>
              </>}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="SIRET"
                  error={!!errors.siret?.message}
                  helperText={errors.siret?.message as string}
                  {...register('siret', {})}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Adresse e-mail professionnelle"
                  autoComplete="email"
                  type="email"
                  error={!!errors.email?.message}
                  helperText={errors.email?.message as string}
                  {...register('email', {
                    required: true,
                    minLength: {
                      value: 4,
                      message: 'L\'email doit contenir au moins 4 caractères'
                    },
                    maxLength: {
                      value: 50,
                      message: 'L\'email ne doit pas dépasser 50 caractères'
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "La valeur entrée n'est pas une adresse email valide"
                    }
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  label="Mot de passe"
                  type="password"
                  autoComplete="new-password"
                  error={!!errors.password?.message}
                  helperText={errors.password?.message as string}
                  {...register('password', {
                    required: true,
                    minLength: {
                      value: 8,
                      message: 'Le mot de passe doit avoir au moins 8 caratères'
                    },
                    maxLength: {
                      value: 32,
                      message: 'Le mot de passe ne doit pas dépasser 16 caractères'
                    }
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  label="Confirmation du mot de passe"
                  type="password"
                  autoComplete="new-password"
                  error={!!errors.confirm_password?.message}
                  helperText={errors.confirm_password?.message as string}
                  {...register("confirm_password", {
                    required: true,
                    validate: (val: string) => {
                      if (watch('password') != val) {
                        return "Les mots de passe ne correspondent pas";
                      }
                    },
                   })}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <ul>
                      <li>
                        <FormControlLabel
                          control={<Checkbox color="primary" />}
                          label={<span>J'accepte la <a target="_blank" href="https://vertexa.fr/Confidentialite_Utilisateurs/">Politique de confidentialité Utilisateurs</a></span>}
                          {...register('dataUsage', { required: true })}
                        />
                      </li>
                      <li>
                        <FormControlLabel
                          control={<Checkbox color="primary" />}
                          label={<span>J'accepte les  <a target="_blank" href="https://vertexa.fr/CG-CGU_Utilisateurs/">Conditions Générales et les Conditions Générales Utilisateurs</a></span>}
                          {...register('information', { required: true })}
                        />
                      </li>
                    </ul>
                    {(errors.dataUsage || errors.information) && <Typography align='center' sx={{
                      color: theme.palette.error.light
                    }}>
                        Toute les cases doivent être coché pour valider le compte
                      </Typography>}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Les champs avec une astérisque sont obligatoires
                </Typography>
              </Grid>
            </Grid>
            {
            isSubmitSuccessful ? 
              <Grow in={isSubmitSuccessful}><Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                <Typography variant="h6">Un e-mail vous a été envoyé, pour terminer la création de votre compte, merci de cliquer sur le lien que vous avez reçu par mail</Typography>
              </Box></Grow>
            :
            isSubmitting ?
            <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></Box>
            : <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Valider
            </Button>}
            <Grid container justifyContent="flex-end">
              <Grid item>
                {!isSubmitSuccessful && <Link href="/login" variant="body2">
                  Se connecter
                </Link>}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
  );
}