import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useTheme } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import WarningIcon from '@mui/icons-material/Warning';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { getStyle } from '../../components/Drawer';

const PractitionerDrawer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    return (
        <List>
            <ListItem key='accueil' disablePadding sx={getStyle(location.pathname === '/', theme)}>
                <ListItemButton onClick={() => navigate('/')}>
                    <ListItemIcon>
                        <HomeIcon sx={getStyle(location.pathname === '/', theme)}/>
                    </ListItemIcon>
                    <ListItemText primary='Accueil' />
                </ListItemButton>
            </ListItem>
            <ListItem key='patients' disablePadding sx={getStyle(location.pathname.startsWith('/pro/patients'), theme)}>
                <ListItemButton onClick={() => navigate('/pro/patients')}>
                    <ListItemIcon>
                        <PeopleIcon sx={getStyle(location.pathname.startsWith('/pro/patients'), theme)} />
                    </ListItemIcon>
                    <ListItemText primary='Mes patients' />
                </ListItemButton>
            </ListItem>
            <ListItem key='documents' disablePadding>
                <ListItemButton href="https://vertexa.fr/Interface_PS/" target="_blank">
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary='Mes documents et tutoriels' />
                </ListItemButton>
            </ListItem>
            <ListItem key='bugs' disablePadding>
                <ListItemButton href="https://tinyurl.com/2wwvj76w" target="_blank">
                    <ListItemIcon>
                        <WarningIcon />
                    </ListItemIcon>
                    <ListItemText primary='Déclarer un Bug' />
                </ListItemButton>
            </ListItem>
            <ListItem key='update' disablePadding>
                <ListItemButton href="https://vertexa.fr/videos/Tuto_MiseAJour.mp4" target="_blank">
                    <ListItemIcon>
                        <SystemUpdateAltIcon />
                    </ListItemIcon>
                    <ListItemText primary='Mettre à jour le logiciel' />
                </ListItemButton>
            </ListItem>
        </List>
    );
};

export default PractitionerDrawer;
