import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

interface BasicCardProps {
    primaryText: string,
    title: string
}

export default function BasicCard(props: BasicCardProps) {
  return (
    <Card sx={{ maxWidth: 100, display: 'flex', justifyContent: 'center' }}>
      <CardContent>
        <Typography variant='h3' gutterBottom textAlign='center'>
          {props.primaryText}
        </Typography>
        <Typography variant="body2"  color="text.secondary" textAlign='center'>
          {props.title}
        </Typography>
      </CardContent>
    </Card>
  );
}