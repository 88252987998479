import React from 'react';
import { Routes, Route } from "react-router-dom";

import Patient from './Patient';
import Patients from './Patients';
import Profile from './Profile';
import { Practitioner } from '../../models/Practitioner';

interface RoutesProps {
    me: Practitioner
}

const PractitionerRoutes = (props: RoutesProps) => {
    return (
        <Routes>
            <Route path="/profile" element={<Profile me={props.me} />} />
            <Route path="/patients" element={<Patients />} />
            <Route path="/patients/:id" element={<Patient />} />
        </Routes>
    );
};

export default PractitionerRoutes;
